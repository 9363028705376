import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/styles/sass/global.scss";
import i18n from "./system/lang/lang.js";
import { createPinia } from "pinia";
import VueQRCodeComponent from 'vue-qrcode-component';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import {
  getMemberInfo, getUserNewsList, getStakingType, getContractAddress, authorizeUser, getBannerList, getDownloadLink, getredeemBoostRec
} from "@/system/request/api/api";
import { useMetatool } from '@/system/crypto/config';

Vue.use(VueGoodTablePlugin);
Vue.component('qr-code', VueQRCodeComponent);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(createPinia())

Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();

new Vue({
  data() {
    return {
      isLoading: false,
      needRiskLevel: false,
      cannotUpgrade: true,
      lock: false,
      pageNumber: 1,
      userInfo: {
        ref_code: '',
        d_password2: null,
        d_password: null,
        invest: 0,
        self_invest: 0,
        user_group_id: 0,
        boost_limit: {
          current: 0,
          cap: 0,
        },
        rank: {
          id: 0,
          rank_name_en: ''
        },
        package: {
          price: 0,
        }
      },
      globalInfo: {
        BOOST_PRICE: '',
        BOOST_POOL: '',
        USDTB_POOL: '',
        VIPI_PRICE: '',
      },
      bannerList: [],
      selectedWallet: '',
      currentAddress: '',
      username: '',
      memberList: [],
      teamList: [],
      nodeList: [],
      newsList: [],
      lastNew: '',
      packageList: [],
      newsItem: {
        description: ''
      },
      assetInfo: {
        static_bonus: 0,
        special_bonus: 0,
        dynamic_bonus: 0,
        level_bonus: 0,
      },
      modal: {
        title: "",
        msg: "",
        action: false,
        error: false,
      },
      contractInfo: {
        address: ''
      },
      transactionIsLoading: false
    }
  },
  watch: {
    '$i18n.locale'() {
    },

    'userInfo'() {
      this.checkCurrentDate();
    }
  },
  methods: {
    changeLan(lan) {
      if (lan == 'zh' || lan == 'en' || lan == 'in' || lan == 'th' || lan == 'ja' || lan == 'ko' || lan == 'vn' || lan == 'ph') {
        localStorage.setItem('lan', lan);
        this.$i18n.locale = lan;

      } else {
        this.$root.makeToast("warning", this.$t('coming_soon'));
      }
    },
    getStakingType() {
      var self = this;
      self.isLoading = true;
      var result = getStakingType();
      result
        .then(function (value) {
          self.packageList = value.data.data.package_type;
          self.isLoading = false;


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkCurrentDate() {
      if (this.userInfo.id == '1000057') {
        this.cannotUpgrade = false;
        console.log(this.cannotUpgrade);
        return;
      }
      this.cannotUpgrade = true;
      var todayDate = new Date().getDate();
      if (todayDate <= 3) {
        if (this.userInfo.user_group_id == 0 || this.userInfo.user_group_id >= 3) {
          this.cannotUpgrade = false;

        }
        if (this.userInfo.user_group_id >= 3 && this.userInfo.risk_level == 0)
          this.needRiskLevel = true;

      } else {
        if (this.userInfo.user_group_id == 0) {
          this.cannotUpgrade = false;

        }
      }

      console.log(this.cannotUpgrade);
    },
    getInfo() {
      var self = this;
      self.isLoading = true;
      var result = getMemberInfo();
      result
        .then(function (value) {
          self.isLoading = false;
          self.userInfo = value.data.data;
          if (value.data.data.password2 == null) {
            self.$router.push('/web/set-sec-password');

          }

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getNewsList() {
      var result = getUserNewsList(this.$i18n.locale, 1, 1);
      var self = this;
      result
        .then(function (value) {
          self.newsList = value.data.data.record.data;
          self.lastNew = value.data.data.latest;
          var popup = localStorage.getItem('popup') ?? '0';

          if (popup == '1') {
            setTimeout(() => {
              for (let i = 0; i < self.lastNew.length; i++) {
                console.log(self.lastNew[i]);
                self.$bvModal.show('modal-newlatest-' + self.lastNew[i].id);
              }

            }, 1000);
            localStorage.setItem('popup', '0');

          }

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getBannerList() {
      this.bannerList = [];
      var result = getBannerList(
        this.$i18n.locale === 'zh' ? 'zh' :
          this.$i18n.locale === 'ja' ? 'ja' :
            this.$i18n.locale === 'th' ? 'th' :
              this.$i18n.locale === 'ko' ? 'ko' :
                this.$i18n.locale === 'in' ? 'in' :
                  this.$i18n.locale === 'vn' ? 'vn' :
                   this.$i18n.locale === 'ph' ? 'ph' :
                  'en',
        2,
        1
      );
      var self = this;
      result
        .then(function (value) {

          self.bannerList = value.data.data;
          console.log(self.bannerList);


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    makeToast(variant = null, msg, type) {
      this.msg = msg;
      this.variant = variant;
      this.type = type;
      this.$bvToast.toast(msg, {
        // // title: ` ${variant || "default"}`,
        variant: variant,
        solid: true,
      });
    },
    checkKYC() {
      if (this.userInfo.check_kyc.can_withdraw == 'approved') {
        this.$bvModal.show('withdraw');

      } else {
        this.modal = {
          title: this.$t('kyc'),
          msg: this.$t('kyc_hint'),
          action: false,
          error: true,
        };
        this.$bvModal.show('modal-errorMsg');

      }
    },

    getContractAddressInfo() {
      var result = getContractAddress();

      var self = this;
      this.$root.isLoading = true;
      result
        .then(function (value) {
          console.log(value);
          self.contractInfo = value.data.data;
          self.getAccounts(value.data.data.address);
        })
        .catch(function (error) {
          console.log(error);
        });

    },
    getAccounts(addr) {
      let metaTool = useMetatool();
      metaTool.connectWallet().then(res => {
        if (res.length > 0) {
          metaTool.accounts(addr).then(acc => {
            if (!acc)
              this.authorizeUser();
            else
              this.isLoading = false;

          });
        } else {
          this.connect(addr);
        }
      });

    },
    connect(addr) {
      let metaTool = useMetatool();
      metaTool.connectWallet().then(res => {
        console.log('tirgger');
        if (res.length > 0) {
          this.getAccounts(addr);

        } else {
          this.$root.makeToast("danger", 'No address connected in your wallet!', 'danger');
        }

      }).catch(function (error) {
        console.log(error);
      });
    },
    authorizeUser() {
      var self = this;
      self.isLoading = true;
      var result = authorizeUser();
      result
        .then(function (value) {
          self.isLoading = false;
          console.log(value.data)

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    clearErrorMsg() {
      this.$root.modal = {
        title: "",
        msg: "",
        action: false,
        error: false,
      };
      console.log(this.modal);

    },
    getRecord() {
      var result = getredeemBoostRec(this.pageNumber)
      var self = this;
      self.$root.isLoading = true;
      result
        .then(function (value) {
          self.lock = value.data.data.lock;
          self.$root.isLoading = false;
        }).catch(function (error) {
          console.log(error);
          self.$root.isLoading = false;
        });
    },
    getGlobalInfo() {
      var self = this;
      self.isLoading = true;
      var result = getDownloadLink();
      result
        .then(function (value) {
          self.isLoading = false;
          self.globalInfo = value.data.data.system;
        })
        .catch(function () { });
    },
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
